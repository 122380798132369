import React, { memo } from 'react';
import TableChair from "./TableChair";

const toRadians = (angle) => {
  return angle * (Math.PI / 180);
};

const RoundTableChairs = ({ table, isSelected }) => {
  const angleChange = 360 / table.seats;

  return [...Array(table.seats).keys()].map(index => {
    const props = {
      width: 24,
      x: Math.cos(toRadians(90 + angleChange * index)) * (table.width / 2 + 6) + table.width / 2,
      y: -Math.sin(toRadians(90 + angleChange * index)) * (table.width / 2 + 6) + table.width / 2,
      rotation: -angleChange * index,
    };
    return <TableChair key={`${table.id}-chairs-${index + 1}`} {...props} selected={isSelected} />;
  });
};

export default memo(RoundTableChairs);
