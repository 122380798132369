import React, { useEffect, useState } from "react";
import { Group, Image, Layer, Rect, Stage, Text } from "react-konva";
import Modal from "../../../components/feedback/Modal/Modal";
import mova from 'mova';
import './SelectSpotModal.scss';
import { connect } from "react-redux";
import { closeModal } from "@store/actions/modal";
import RoundTable from "./components/RoundTable";
import RectangularTable from "./components/RectangularTable";
import LoungeTable from "./components/LoungeTable";
import SlickSlider from "react-slick";
import Button from "../../../components/inputs/Button/Button";
import Colors from '@data/enums/Color.enum';
import { renderToStaticMarkup } from "react-dom/server";
import { users } from '../../../theme/icons';
import { getDeposits } from "@store/actions/place";
import SelectDepositTypePopup from "./components/SelectDepositTypePopup";
import { addReservation } from '@actions/reservation';
import moment from 'moment';

const t = mova.ns('containers.SelectSpotModal');

const svgToDataURL = (SvgComponent) => {
  const svgString = renderToStaticMarkup(SvgComponent);
  return `data:image/svg+xml;base64,${btoa(svgString)}`;
};

const getTableWidth = (table) => {
  if (table.type === 'LOUNGE') {
    return table.variables?.length * table || (table.variables?.seats === 2 ? 48 : 32);
  } else {
    return table.variables?.length * 32 || 64;
  }
}

const getTableHeight = (table) => {
  if (table.type === 'LOUNGE') {
    return table.variables?.seats === 2 ? table.variables?.length * 4 / 3 * 32 : table.variables?.length * 64;
  } else {
    return table.variables?.width * 32 || 64;
  }
}

const useImage = (src) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = src;
    img.onload = () => setImage(img);
  }, [src]);

  return image;
};

const getDepositAmount = (selected, date) =>
  selected.reduce((acc, item) => acc + (item.selectedDepositType?.[moment(date).isoWeekday()] || 0), 0);

const getSubmitLabel = (place, selected, selectionMandatory, date) => {
  if (!selected.length && !selectionMandatory) {
    return t('skip');
  } else {
    if (selected.some(item => item.deposit)) {
      return `${t('book')} | ${getDepositAmount(selected, date)}${t('uah')}`;
    } else {
      return t('book');
    }
  }
}

const isTableAvailable = (table, slots, time) => !!slots.find(t => t.id === table.id)?.slots?.find(slot => slot.from === time);

const calculateBackgroundSize = (img, stageWidth, stageHeight) => {
  const aspectRatio = img?.width / img?.height;
  const stageAspectRatio = stageWidth / stageHeight;

  let width, height;

  if (aspectRatio < stageAspectRatio) {
    width = stageWidth;
    height = stageWidth / aspectRatio;
  } else {
    height = stageHeight;
    width = stageHeight * aspectRatio;
  }

  return { width, height };
};

const SelectSpotModal = ({ place, meta, closeModal, getDeposits, deposits, date, addReservation, slots }) => {
  const [scale, setScale] = useState(1);
  const [hall, setHall] = useState(place.halls[0]);
  const [selected, setSelected] = useState([]);
  const [preSelected, setPreSelected] = useState(null);

  if (!hall) {
    closeModal();
  }

  useEffect(() => {
    if (place?.uri) {
      getDeposits(place.uri);
    }
  }, [getDeposits, place?.uri]);

  const handleTableClick = (e, table) => {
    if (selected.some(item => item.id === table.id)) {
      setSelected(selected.filter(item => item.id !== table.id));
    } else {
      if (table.deposit) {
        const deposit = deposits.find(d => d.id === table.deposit.id);
        if (deposit) {
          if (deposit.types.length === 1) {
            setSelected([...selected, { ...table, selectedDeposit: deposit, selectedDepositType: deposit.types[0] }]);
          } else {
            setPreSelected({
              deposit,
              table,
              x: e.evt.clientX,
              y: e.evt.clientY,
            });
          }
        }
      } else {
        setSelected([...selected, table]);
      }
    }
  };

  const selectDepositType = (type) => {
    setSelected([...selected, {
      ...preSelected.table,
      selectedDeposit: preSelected.deposit,
      selectedDepositType: type
    }]);
    setPreSelected(null);
  }

  const smoothZoom = (targetScale) => {
    const animationDuration = 100;
    const startTime = performance.now();
    const initialScale = scale;

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / animationDuration, 1);
      const newScale = initialScale + (targetScale - initialScale) * progress;

      setScale(newScale);
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const handleZoom = (direction) => {
    const newScale = direction === 'in' ? scale * 1.2 : scale / 1.2;
    const limitedScale = Math.min(Math.max(newScale, 0.5), 3); // Limit zoom between 0.5 and 3
    smoothZoom(limitedScale);
  };

  const hallButtons = (
    <SlickSlider
      variableWidth
      arrows
      swipeToSlide
      infinite={false}
      centerMode={false}
      slidesToShow={Math.min(3, place.halls.length)}
      slidesToScroll={Math.min(2, place.halls.length)}
      className='select-hall'
      responsive={[
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]}
    >
      {place.halls.map(h => (
        <Button
          inputClass='select-hall__button'
          key={h.id}
          onClick={() => setHall(h)}
          type={hall?.id === h.id ? 'default' : 'flat'}
        >
          {h.name}
        </Button>
      ))}
    </SlickSlider>
  );

  const tables = (hall.spots || []).map(spot => ({
    ...spot,
    width: getTableWidth(spot),
    height: getTableHeight(spot),
  }));

  const personsSvgDataUrl = svgToDataURL(users({}));
  const personIcon = useImage(personsSvgDataUrl);

  const stageWidth = (hall.variables.length || 16) * 32;
  const stageHeight = (hall.variables.width || 16) * 32;
  const hallBackground = useImage(hall.bgCustom);
  const { width: bgWidth, height: bgHeight} = calculateBackgroundSize(hallBackground, stageWidth * scale, stageHeight * scale);
  const selectionMandatory = place.spotsMandatory || (place.showSpots && place.onlyDepositReservations);

  const onSubmit = () => {
    if (selected.length === 0) {
      addReservation(place.uri, meta.time);
    } else {
      addReservation(place.uri, meta.time, selected);
    }
    closeModal();
  }

  return (
    <Modal className='select-spot-modal' title={t('title')}>
      {hallButtons}
      <div className='stage-wrapper'>
        <Stage
          width={stageWidth * scale}
          height={stageHeight * scale}
          scaleX={scale}
          scaleY={scale}
          className='stage'
        >
          <Layer>
            {hall.bgCustom && <Image image={hallBackground} width={bgWidth} height={bgHeight} />}
            {tables.map((table) => (
              <Group
                key={table.id}
                x={table.x}
                y={table.y}
                onClick={(e) => isTableAvailable(table, slots, meta.time) ? handleTableClick(e, table) : null}
                onTap={(e) => isTableAvailable(table, slots, meta.time) ? handleTableClick(e, table) : null}
                opacity={isTableAvailable(table, slots, meta.time) ? 1 : 0.4}
              >
                {table.type === 'ELLIPSE' && <RoundTable table={table} selected={selected} />}
                {table.type === 'RECT' && <RectangularTable table={table} selected={selected} />}
                {table.type === 'LOUNGE' && <LoungeTable table={table} selected={selected} />}
                <Group x={table.width / 2 - 21} y={table.height / 2 - 20}>
                  <Rect
                    x={0}
                    y={0}
                    width={42}
                    height={24}
                    fill={isTableAvailable(table, slots, meta.time) ? Colors.COLOR_PRIMARY : null}
                    cornerRadius={10}
                  />
                  <Text
                    text={table.seats}
                    fontSize={12}
                    fill='black'
                    x={6}
                    y={7}
                    width={14}
                    align='center'
                  />
                  <Image
                    image={personIcon}
                    x={20}
                    y={4}
                    width={16}
                    height={16}
                  />
                </Group>
                <Text
                  text={table.label}
                  fontSize={12}
                  fill={Colors.TEXT_SECONDARY}
                  x={0}
                  y={table.height / 2 + 8}
                  width={table.width}
                  align='center'
                />
              </Group>
            ))}
          </Layer>
        </Stage>
      </div>
      <div className='zoom-controls'>
        <button onClick={() => handleZoom('in')} className='zoom-controls__button'>+</button>
        <button onClick={() => handleZoom('out')} className='zoom-controls__button'>-</button>
      </div>
      <div className='footer'>
        <Button
          disabled={selectionMandatory && !selected.length}
          onClick={onSubmit}
          className='footer__submit'
          type={!selectionMandatory && !selected.length ? 'flat' : 'default'}
        >
          {getSubmitLabel(place, selected, selectionMandatory, date)}
        </Button>
      </div>
      {preSelected &&
        <SelectDepositTypePopup
          data={preSelected}
          typeSelected={selectDepositType}
          date={date}
          onClose={() => setPreSelected(null)}
        />
      }
    </Modal>
  );
};

const mapStateToProps = ({ place, modal, reservation }) => ({
  // toDO: Won't be present on the search page? Fetch place and only then open the modal?
  place: place.details.data,
  deposits: (place.deposits.data || []).filter(d => d.active),
  meta: modal.params,
  date: reservation.quick.date,
  slots: place.slots.data,
});

const mapDispatchToProps = {
  closeModal,
  getDeposits,
  addReservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSpotModal);
