import React, { memo } from 'react';
import { Group, Line, Rect } from "react-konva";
import Colors from '@data/enums/Color.enum';

const LoungeTable = ({ table, selected }) => {
  return (
    <Group
      x={table.width / 2}
      y={table.height / 2}
      offset={{ x: table.width / 2, y: table.height / 2 }}
      rotation={table.variables?.angle || 0}
    >
      <Rect
        width={table.width}
        height={table.height}
        fill='white'
        stroke={Colors.TEXT_SECONDARY}
        strokeWidth={selected.some(item => item.id === table.id) ? 1 : 0}
      />
      <Line
        points={[0, 10, table.width, 10]}
        stroke={Colors.TEXT_SECONDARY}
        strokeWidth={1}
      />
      {table.variables?.seats === 2 && (
        <Line
          points={[table.width / 2, 0, table.width / 2, 10]}
          stroke={Colors.TEXT_SECONDARY}
          strokeWidth={1}
        />
      )}
    </Group>
  );
};

export default memo(LoungeTable);
