import React, { memo } from 'react';
import { Group, Rect } from "react-konva";
import Colors from '@data/enums/Color.enum';
import RectangularTableChairs from "./RectangularTableChairs";

const RectangularTable = ({ table, selected }) => {
  const isSelected = selected.some(item => item.id === table.id);

  return (
    <Group x={0} y={0}>
      <Rect
        width={table.width}
        height={table.height}
        fill='white'
        stroke={Colors.TEXT_BLACK}
        strokeWidth={isSelected ? 1 : 0}
        cornerRadius={4}
      />
      <RectangularTableChairs table={table} isSelected={isSelected} />
    </Group>
  );
};

export default memo(RectangularTable);
