import React, { memo } from 'react';
import { Rect } from "react-konva";
import Colors from '@data/enums/Color.enum';

const TableChair = ({ selected, width, x, y, rotation }) => {
  return (
    <Rect
      width={width}
      height={10}
      x={x}
      y={y}
      offset={{ x: width / 2, y: 8 }}
      rotation={rotation}
      fill='white'
      stroke={Colors.TEXT_BLACK}
      strokeWidth={selected ? 1 : 0}
      cornerRadius={[4, 4, 0, 0]}
    />
  );
};

export default memo(TableChair);
